import React from "react";
import WalletButton from "./components/WalletButton";

const Header = () => {
  return (
    <div>
      <header id="header">
        <div class="header">
          <div class="header_in">
            <div class="logo">
              <a class="has_text" href="index-2.html">
                <img src="img/logo.png" alt="" />
              </a>
            </div>
            <div class="nav">
              <ul>
                <a href="https://2ndcoin.com/#intro">
                  <li>About</li>
                </a>
                <a href="https://2ndcoin.com/#vision">
                  <li>Our Vision</li>
                </a>
                <a href="https://2ndcoin.com/#roadmap">
                  <li>Roadmap</li>
                </a>
                <a href="https://2ndcoin.com/#faqs">
                  <li>FAQs</li>
                </a>
                <a href="https://2ndcoin.com/#contact">
                  <li>Contact</li>
                </a>
                {/*
                  <a  href="#home"><span><img src='img/new/k.png' class="himg" /></span></a>
                </li>
                <li><a  href="#about"><span><img src='img/new/i.png' class="himg" /></span></a></li>
                <li><a  href="#tokenomics"><span><img src='img/new/34.png' class="himg" /></span></a></li>
                <li><a  href="#roadmap"><span><img src='img/new/35.png' class="himg" /></span></a></li>
                
                <li><a  href="#faq"><span><img src='img/new/g.png' class="himg" /></span></a></li>
                <li><a  href="#faq"><span><img src='img/new/36.png' class="himg" /></span></a></li>
                
                */}
              </ul>
            </div>
            <div class="helpful">
              <div>{/* <WalletButton /> */}</div>
              <a href="https://2ndcoin.com/wp-content/uploads/2024/03/2nd-Amendment2.0.pdf">
                <button class="btnnews">Whitepaper</button>{" "}
              </a>
            </div>
            <div class="helpful"></div>
          </div>
        </div>
      </header>

      <div class="metaverse_fn_mobnav">
        <div class="mob_top">
          <div class="logo">
            <a href="index-2.html">
              <img src="img/logo2.png" alt="" />
            </a>
          </div>
          <div class="triggers">
            {/* <div><WalletButton /></div> */}
            {/*
          <a href="#" target="_blank" rel="noreferrer" ><img src="img/new/112.png"  class="himg3" alt="Twitter" /></a>
            <a href="#" target="_blank" rel="noreferrer" ><img src="img/new/113.png"  class="himg3" alt="Twitter" /></a>
            <a href="#" target="_blank" rel="noreferrer" ><img src="img/new/22.png"  class="himg3" alt="Twitter" /></a>
            <a href="#" target="_blank" rel="noreferrer" ><img src="img/new/11.png"  class="himg3" alt="Twitter" /></a>

         
            <a href="#" class="connect_btn" target="_blank" rel="noreferrer"><span class="text">Telegram</span></a>
  */}
            <a class="trigger trigger_menu" href="#">
              <span></span>
            </a>
          </div>
        </div>
        <div class="mob_bot">
          <ul>
            <li>
              <a class="creative_link" href="#home">
                Home
              </a>
            </li>
            <li>
              <a class="creative_link" href="#about">
                About
              </a>
            </li>
            <li>
              <a class="creative_link" href="#faq">
                FAQ
              </a>
            </li>
            <li>
              <a class="creative_link" href="#roadmap">
                Roadmap
              </a>
            </li>
            <li>
              <a class="creative_link" href="#faq">
                How to buy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
