import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import Preloader from "./Preloader";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { bsc } from "wagmi/chains";
import { WagmiProvider } from "wagmi";
import { http } from "@wagmi/core";
import Buy from "./buy";
import BuyWithWagmi from "./buyWithWagmi";

const projectId = process.env.REACT_APP_PROJECT_ID;

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc];
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  transports: {
    [bsc.id]: http(),
  },
});

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  ],
  excludeWalletIds: [
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
  ],
  allWallets: "HIDE",
});

function App() {
  const queryClient = new QueryClient();

  return (
    <div>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <Preloader />
          <div class="metaverse_fn_main">
            <Header />
            <div class="metaverse_fn_page">
              {/* <Buy /> */}
              <BuyWithWagmi />
              {/* <section id="parallax">
          <div class="myparallax" data-bg-img="img/parallax/6.png" data-parallax="yes"></div>
          <div class="particle_wrapper"><div id="particles-js"></div></div>
        </section> */}
            </div>
            <Footer />
          </div>
        </QueryClientProvider>
      </WagmiProvider>
    </div>
  );
}

export default App;
