import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { parseEther } from "viem";
import swapContractAbi from "./abis/swapContract.json";
import {
  writeContract,
  waitForTransactionReceipt,
  readContract,
} from "@wagmi/core";
import { config } from "./App";
import { useAccount } from "wagmi";
import WalletButton from "./components/WalletButton";
import { ethers } from "ethers";

const CONTRACT_ADDRESS = "0x5892363a068Aa740A26E79b079f968Ec4906499f";

const BuyWithWagmi = () => {
  const [chicoAmount, setChicoAmount] = useState("");
  const { isConnected } = useAccount();
  const [bnbValue, setBnbValue] = useState(0);

  const calculate = (event) => {
    const field1Value = parseFloat(event.target.value);

    if (!isNaN(field1Value)) {
      const result = field1Value * 2000000;
      setChicoAmount(result + "");
    } else {
      setChicoAmount("");
    }
  };

  const buyBnbToken = async (value) => {
    buyBnbTokennns(value);
  };

  const handleSubmit = async (value) => {
    if (!bnbValue) {
      toast.error("Please enter amount");
      return;
    }
    if (isConnected) {
      await buyBnbToken(value);
    } else {
      toast.error("Wallet is not connected");
    }
  };

  const buyBnbTokennns = useCallback(async (value) => {
    try {
      const amount = ethers.parseEther(value.toString());

      const hash = await writeContract(config, {
        abi: swapContractAbi,
        address: CONTRACT_ADDRESS,
        functionName: "swapTokens",
        value: amount?.toString(),
      });
      const transactionstatus = await waitForTransactionReceipt(config, {
        hash,
      });

      if (transactionstatus?.status === "success") {
        toast.success("Token Buy successfully");
        setBnbValue(0);
      } else {
        toast.error("Transaction failed");
      }
    } catch (error) {
      toast.error("Transaction failed");
    }
  }, []);

  return (
    <div id="about">
      <section id="buy">
        <div className="container">
          <div className="fn_cs_title">
            <h3 className="title" data-color="black">
              Buy 2nd
            </h3>
            <div className="divider" data-color="1">
              <div className="line"></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="fn_row">
            <div className="fn_col2">
              <h3 className="title22">How to Buy 2nd?</h3>
              <p className="data-color">
                Step1: Download{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  style={{ "text-decoration": "none" }}
                  href="https://metamask.io/download/"
                >
                  MetaMask
                </a>
                &nbsp;or&nbsp;
                <a
                  rel="noreferrer"
                  target="_blank"
                  style={{ "text-decoration": "none" }}
                  href="https://trustwallet.com/download"
                >
                  Trust Wallet
                </a>{" "}
                <br />
                Step2: Buy BNBs from binance or your wallet
                <br />
                Step3: Send your BNBs to your&nbsp;
                <a
                  rel="noreferrer"
                  target="_blank"
                  style={{ "text-decoration": "none" }}
                  href="https://metamask.io/download/"
                >
                  MetaMask
                </a>
                &nbsp;or&nbsp;
                <a
                  rel="noreferrer"
                  target="_blank"
                  style={{ "text-decoration": "none" }}
                  href="https://trustwallet.com/download"
                >
                  Trust Wallet
                </a>
                . Your wallet should be connected to Binance/BNB chain
                <br />
                Step4: Click Buy 2nd button
                <br />
                Step5: Enter desired No. of 2nd you want to buy and click on buy
                2nd button to connect your wallet <br />
                Step6: Confirm Transaction
                <br />
                2nd are in your wallet Now, Hold 2nd to enjoy the ride
              </p>
            </div>
            <div className="fn_col2 fn_col3">
              <h3 className="pl">Presale is Live Buy 2nd</h3>
              <h3 className="sol2">1 BNB = 2,000,000 2nd</h3>
              <div className="input-group">
                <div className="inline-inputs">
                  {
                    <label htmlFor="field1" className="labled">
                      BNB
                    </label>
                  }
                  <input
                    type="text"
                    id="field1"
                    value={bnbValue}
                    onChange={(e) => setBnbValue(e.target.value)}
                    placeholder="1"
                    onInput={calculate}
                  />
                </div>
                <div className="inline-inputs">
                  {
                    <label htmlFor="field2" className="labled">
                      2nd
                    </label>
                  }
                  <input
                    type="text"
                    id="field2"
                    placeholder="2,000,000"
                    value={chicoAmount}
                    readOnly
                  />
                </div>
              </div>

              {/* <div onClick={openPopup} className='conw'>
            <img src='img/new/t.png'  />
            </div> */}
              {!isConnected ? (
                <WalletButton />
              ) : (
                <button
                  class="btnnew"
                  //  onClick={() => swapTokens()}
                  onClick={() => {
                    handleSubmit(bnbValue);
                  }}
                >
                  Buy 2nd
                </button>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
      {/*   
        {/* // <div Name="popup-container">
        //   <div className="popup">
        //     <button className="close-button" onClick={closePopup}>
        //       X
        //     </button>
        //     <div className="popup-header">
        //       <h2 className="ptext">Connect Wallet</h2>
        //     </div>
        //     <div className="popup-content">
        //       <button onClick={connectMetaMask} className="block-button">
        //         <span>
                  <img */}
      {/* src="img/metamask.png"
                    width={"30px"}
                    alt="metamask-icon" */}
      {/* />
                </span>
                <span className="phantom"> &nbsp;&nbsp;MetaMask</span>
              </button>

              <button onClick={connectTrustWallet} className="block-button">
                <span>
                  <img
                    src="img/trust-wallet.png"
                    width={"35px"}
                    alt="trust-wallet-icon"
                  />
                </span>
                <span className="phantom"> TrustWallet</span>
              </button>

              <br />
              <h2 className="donot">
                If you don't have a wallet download it from
                <br />
                <br />
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://metamask.io/download/"
                  className="link"
                >
                  MetaMask
                </a>
                <br />
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://trustwallet.com/download"
                  className="link"
                >
                  TrustWallet
                </a>
              </h2>
            </div>
          </div> */}
      {/* </div> */}
    </div>
  );
};

export default BuyWithWagmi;
