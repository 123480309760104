import React from 'react'

const Preloader = () => {
  return (
    <div>
    <div class="metaverse_fn_preloader">
      <div class="multi-spinner-container">
        <div class="multi-spinner">
        <div class="box1"></div>
        <div class="box1"></div>
        <div class="box1"></div>
        <div class="box1"></div>
        </div>

      </div>
    </div>
    </div>
  )
}

export default Preloader
