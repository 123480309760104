import React from 'react'

const Footer = () => {
  return (
    <div>
            <footer id="footer">
        <div class="container">
          <div class="footer">
            <div class="left_part">
              <p>Copyright 2024 — 2nd Amendment
              </p>
            </div>
            {/* <div class="right_part">
              
              <div class="social_icons">
                <ul>
                  <li><a href="https://twitter.com/familytokenoffl" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/twitter-1.svg" alt="Twitter" class="fn__svg"/></a></li>
                  <li><a href="https://t.me/FamilyTokenOfficial" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/telegram.svg" alt="Telegram" class="fn__svg"/></a></li>
                  <li><a href="https://discord.com/invite/familytoken" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/discord.svg" alt="Discord" class="fn__svg"/></a></li>
                  <li><a href="https://www.instagram.com/thefamilytoken/" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/instagram-1.svg" alt="Instagram" class="fn__svg"/></a></li>
                  <li><a href="https://www.tiktok.com/@familytoken" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/tiktok.svg" alt="Tiktok" class="fn__svg"/></a></li>
                  <li><a href="https://www.youtube.com/@TheFamilyToken/shorts" target="_blank" rel="noreferrer" class="polygon-bg"><img src="svg/social/youtube.svg" alt="Youtube" class="fn__svg"/></a></li>
                
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      
      <a href="#" class="metaverse_fn_totop">
        <span class="icon"></span>
        <span class="arrow"></span>
        <span class="rocket"></span>
      </a>
    </div>
  )
}

export default Footer
